.hero2 {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 60vh;
  position: relative;
}
.hero2::before {
  content: "";
  background: 
  url("https://www.etatvasoft.com/blog/wp-content/uploads/2020/06/reactjs-for-web-development-why-where-to-use.jpg"
  );
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero2 h1 {
    font-size: 2.4rem;
}
.hero2 p{
    font-size: 1.4rem;
    text-align: center;
}
@media screen and (max-width:640px) {
    .hero2 h1 {
        font-size: 2rem;
    }
    
}
